<template>
  <div class="intraday-single-wrapper" v-if="visible" @click="close">
    <div class="intraday-single" v-on:click.stop>

      <div class="chart top-view">
        <div class="close-single">
          <span @click="close"><i class="fas fa-times"></i></span>
        </div>
        <b-row>
          <b-col lg="4" class="left-col">
            <h3 v-if="selected_intraday_update.linked_instruments.length > 0">
              <span v-for="(linked_instrument, index) in selected_intraday_update.linked_instruments"
                    v-bind:key="linked_instrument.id"
                    v-html="comma_to_list(linked_instrument.name, selected_intraday_update.linked_instruments.length - 1, index)"
              ></span>
            </h3>
            <img v-for="market in selected_intraday_update.markets"
                 v-bind:key="market.id"
                 class="market-icon"
                 :src="require(`@/assets/icon-${market.slug}.svg`)"
                 alt="Market"
            >

            <h5><time-ago :refresh="30" :long="true" :datetime="selected_intraday_update.publish_date" /></h5>
            <p v-html="selected_intraday_update.content"></p>

          </b-col>
          <b-col class="right-col">
            <base-tabs
              :tabs=selected_intraday_update.charts
              :active_tab_id=intraday_update_chart_active_id
            >
            </base-tabs>
          </b-col>
        </b-row>
      </div>
      <div v-if="selected_intraday_update.linked_instruments.length > 0">
        <div class="chart"
             :class="{'chart-closed': !is_single_intraday_open[instrument.id]}"
             v-for="instrument in selected_intraday_update.linked_instruments"
             v-bind:key="instrument.id"
        >
          <div class="toggle-single" @click="toggle_single_chart(instrument.id)">
            <i :class="!is_single_intraday_open[instrument.id] === true ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i>
          </div>

          <b-row>
            <b-col lg="4" class="left-col">
              <h3 v-html="instrument.name"></h3>
              <i class="fa-star"
                :class="{ fas: is_favourite(instrument.id), far: !is_favourite(instrument.id) }"
                @click.stop="toggleFavouriteItem(instrument)"
              ></i>

              <img alt="Bias" v-b-tooltip="{trigger: 'hover'}" :title="instrument.bias.description" class="img-fluid" v-bind:src="biasIconUrl(instrument.bias.key, { big: true })">

              <h4>{{ $t('components.intraday_updates.primary_analysis') }}</h4>

              <h5>
                <time-ago :refresh="60" :long="true" :datetime="instrument.updated_at" />
              </h5>
              <p v-html="instrument.content"></p>
            </b-col>

            <b-col class="right-col">
              <base-tabs
                :tabs=instrument.charts
                tooltip_trigger='hover'
                image_tooltip_trigger='hover'
                :bias_icon_size=false
              >
              </base-tabs>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseTabs from '../../components/BaseTabs.vue'

export default {
  props: ['intraday_update_id', 'intraday_update_chart_active_id'],
  data () {
    return {
      visible: false,
      is_single_intraday_open: []
    }
  },
  components: {
    BaseTabs
  },
  computed: {
    ...mapState({
      intraday_updates: state => state.intraday_updates.intraday_updates
    }),
    ...mapGetters({
      is_favourite: 'market/instruments/is_favourite'
    }),
    selected_intraday_update (intraday_update_id) {
      return this.intraday_updates.find(intraday_update => intraday_update.id === this.intraday_update_id)
    }
  },
  methods: {
    toggle_single_chart (instrument_id) {
      this.$set(this.is_single_intraday_open, instrument_id, !this.is_single_intraday_open[instrument_id])
    },
    comma_to_list (text, list_length, index) {
      if (index !== list_length) {
        return text + ', '
      }

      return text
    },
    toggleFavouriteItem (instrument) {
      this.$store.dispatch('market/instruments/updateFavourites', { favourite_id: instrument.id })
    },
    show (intraday_update_id) {
      this.visible = true
    },
    close () {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">

.intraday-single-wrapper {
  background-color: var(--white-70-black-70);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 350;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  text-align: left;
  padding: 100px 40px;
  cursor: default;

  div.intraday-single {
    max-width: 1120px;
    margin: 0 auto;

    .close-single {
      position: absolute;
      right: 40px;
      top: 35px;
      color: var(--cool-grey);
      cursor: pointer;
      z-index: 150;
    }

    .chart {
      padding: 40px 80px 70px;
      background-color: var(--white-black);
      margin-bottom: 0;
      border: 1px solid var(--pale-grey-battleship-grey-three);
      margin-bottom: 0;
      border-bottom: 0;
      position: relative;
      max-height: 1000vh;
      overflow: visible;
      @include ease-transition;

      &.top-view {
        background-color: var(--white-dark-slate-gray);
        padding-top: 60px;
        padding-bottom: 100px;

        .left-col .market-icon {
          float: none;
          margin-top: -6px;
          margin-left: 0;
          margin-right: 5px;
        }

        ::v-deep .nav-tabs {
          line-height: 0;

          .nav-link {
            background-color: var(--white-black);

            h4,
            small {
              color: var(--flat-blue-two-white);
            }

            &.active {
              background-color: var(--muted-blue);

              h4 {
                color: var(--white);
              }

              small,
              .v-time-ago__text {
                color: var(--white);
                opacity: 1;
              }
            }

            h4 {
              line-height: 1.3;
              padding: 6px 15px 2px;
              font-weight: bold;
              font-size: 11px;
            }

            small,
            .v-time-ago__text {
              display: block;
              float: none;
              line-height: 1.85;
              padding-bottom: 0;
              text-transform: lowercase;
            }
          }
        }
      }

      .toggle-single {
        position: absolute;
        height: 68px;
        width: 68px;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 100;

        i {
          position: absolute;
          right: 35px;
          line-height: 68px;
          color: var(--pale-grey);
        }
      }

      &.chart-closed {
        padding: 0 40px 0 80px;
        background-color: var(--white-black);
        max-height: 68px;
        overflow: hidden;

        .toggle-single {
          width: 100%;
        }

        .dropdown-icon-wrapper {
          display: block;
          float: right;
        }

        .left-col {
          max-width: 100%;
          flex: none;

          h3 {
            line-height: 68px;
          }

          h4 {
            display: inline-block;
            color: var(--cool-grey);
            font-size: 12px;
            font-weight: 300;
            margin-left: 20px;
            position: relative;
            top: -3px;
          }

          i {
            display: none;
          }

          img {
            margin-top: -6px;
            float: unset;
            margin-left: 10px;
          }

          h5,
          ::v-deep .v-time-ago__text {
            @include ease-transition;
            float: right;
            line-height: 67px;
            margin-right: 20px;
          }
        }

        .right-col {
          margin-top: 80px;
        }
      }

      &:last-child {
        border-bottom: 1px solid var(--pale-grey-battleship-grey-three);
      }

      .dropdown-icon-wrapper {
        display: none;
      }

      .left-col {
        h3 {
          font-size: 20px;
          color: var(--charcoal-grey-white);
          display: inline-block;
          margin-right: 10px;
        }

        i {
          @include ease-transition(1s);
          cursor: pointer;
          color: var(--cloudy-blue);
          vertical-align: text-top;
          font-size: 13px;
          margin-top: 2px;
        }

        img {
          float: right;
          margin-left: 0;
          margin-top: 0;
        }

        h4 {
          display: none;
        }

        h5,
        ::v-deep .v-time-ago__text {
          color: var(--cool-grey);
          font-size: 12px;
          font-weight: 300;
          line-height: 2;
          margin-bottom: 20px;
          display: block;
        }

        p {
          font-size: 15px;
          font-family: 'Athelas', arial, sans-serif;
          letter-spacing: .09px;
          color: var(--charcoal-grey-white);
          line-height: 1.73;
          margin-bottom: 0;
        }

        a {
          font-size: 14px;
          color: var(--muted-blue);
          font-family: Heebo;
          cursor: pointer;
        }

        @media (min-width: 1200px) {
          margin-right: 45px;
        }
      }

      .right-col {
        margin-top: 0;
        text-align: left;

        ::v-deep .tab-content {
          height: auto;
          overflow-y: unset;

          img {
            width: 100%;
          }
        }
      }

      ::v-deep .nav-tabs {
        margin-bottom: 35px;
        border-bottom: 0;
        line-height: 0;

        .nav-item {
          width: 33.333%;
          margin-bottom: 0;
          text-align: left;

          &:first-child .nav-link {
            border-right: 0;
          }

          &:last-child .nav-link {
            border-left: 0;
          }

          &:last-child {
            display: block;
          }

          &:only-child .nav-link {
            border-left: 1px solid var(--ice-blue-battleship-grey-three);
            border-right: 1px solid var(--ice-blue-battleship-grey-three);
          }

          img {
            width: 100%;
          }
        }

        .nav-link {
          color: var(--muted-blue);
          border: 0;
          border-radius: 0;
          padding: 0;
          position: relative;
          border: 1px solid var(--ice-blue-battleship-grey-three);

          h4 {
            font-size: 12px;
            margin-bottom: 0;
            color: var(--charcoal-grey-white);
            line-height: 37px;
            padding: 0 15px;
          }

          small {
            font-size: 10px;
            color: var(--cloudy-blue);
            float: right;
            line-height: 37px;
          }

          img {
            position: inherit;
            transform: inherit;
            width: auto;
            padding: 0px;
            margin: 0 28px 0 15px;
          }

          &.active {
            h4 {
              color: var(--white);
            }

            img {
              filter: brightness(0) invert(1);
            }

            small {
              color: var(--white);
              opacity: 0.6;
            }

            background-color: var(--muted-blue);
          }

          &:after {
            content: '';
            width: 0;
          }

          @media (max-width: 767.98px) {
            h4 {
              line-height: 1;
              padding: 10px 0 5px;
            }

            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
              margin: 0;
            }

            small {
              float: none;
              display: block;
              line-height: 2;
            }
          }
        }
      }

      @media (max-width: 575.98px) {
        padding: 25px
      }

      @media (min-width: 1200px) and (max-width: 1599.98px) {
        ::v-deep .nav-tabs {
          .nav-link {
            h4 {
              line-height: 1;
              padding: 10px 0 5px;
            }

            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 15px;
            }

            small {
              float: none;
              display: block;
              line-height: 2;
            }
          }
        }
      }

      ::v-deep .tab-content {
        img {
          border: 1px solid var(--pale-grey-battleship-grey-three);
          margin-bottom: 15px;
          width: 100%
        }

        .trading-note {
          border: 1px solid var(--muted-blue-06);
          background-color: var(--pale-grey-two);
          padding: 20px;

          h4 {
            font-size: 13px;
            color: var(--muted-blue);
            margin-bottom: 8px;
          }

          p {
            font-size: 11px;
            color: var(--battleship-grey);

            ::v-deep span {
              margin-right: 30px;
              color: var(--charcoal-grey-white);
            }
          }

          a {
            color: var(--muted-blue);
            display: block;
            text-align: center;
            font-size: 11px;

            &:after {
              content: '\f078';
              font-family: 'Font Awesome 5 Pro';
              font-weight: 900;
              font-size: 9px;
              margin-left: 5px;
            }

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1500px) {
    padding: 50px;

    div.intraday-single .chart.top-view {
      padding-bottom: 50px;
    }
  }

  @media (max-width: 991.98px) {
    padding: 25px;
    background-color: var(--white-70);

    div.intraday-single .chart {
      padding: 45px 45px 80px;

      &.top-view {
        padding: 45px 45px 80px;
      }

      &.chart-closed {
        padding: 0 45px;
      }

      .left-col {
        margin-bottom: 40px;

        img {
          float: unset;
          margin-top: -6px;
          margin-left: 20px;
        }
      }

      .right-col {
        ::v-deep .nav-tabs {
          margin-bottom: 25px;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    padding: 0;

    div.intraday-single {
      .close-single {
        top: 33px;
      }

      .chart {
        padding: 20px 25px 70px;

        .toggle-single i {
          right: 25px;
        }

        &.top-view {
          padding: 45px 25px 85px;
        }

        &.chart-closed {
          padding: 0 25px;

          .left-col h5 {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.dark-theme .intraday-single-wrapper div.intraday-single .chart:not(.top-view) .left-col img {
  filter: brightness(0) invert(1);
}

</style>
