<template>
  <div>
    <div class="mobile-menu d-sm-none" @click="toggleInstrumentsMobileMenu()">
      <div class="v-html" v-html="current_instrument_name"></div>

      <span class="dropdown-icon-wrapper">
        <i class="fas fa-chevron-down dropdown-icon"></i>
      </span>
    </div>

    <div class="instruments-bar d-sm-block" :class="{'d-none': is_mobile_menu_closed}">
      <ul>
        <li class="search">
          <input type="text" :placeholder="$t('components.instruments_bar.search_placeholder')" v-model="search" />
          <span class="close d-sm-none" @click="toggleInstrumentsMobileMenu()"><i class="fas fa-times"></i></span>
        </li>

        <!--<li @click="add_all_to_favourites()">{{ $t('components.instruments_bar.add_all_to_favourites') }}</li>-->

        <li :class="{ active: is_active === 'all' }"
            @click="select_intrument({name: $t('components.instruments_bar.all_items'), slug: 'all'}), handle_current_instrument_name($t('components.instruments_bar.all_items'))"
            v-if="$route.params.instrument_slug === 'favourites'"
        >{{ $t('components.instruments_bar.all_items') }}</li>

        <li :class="{ active: is_active === 'all' }"
            @click="scroll_to('.instruments', 'all'), handle_current_instrument_name($t('components.instruments_bar.all_items'))"
            v-if="$route.params.instrument_slug !== 'favourites'"
        >{{ $t('components.instruments_bar.all_items') }}</li>

        <li :class="{ active: is_active === 'favourites' }"
            @click="select_intrument({name: $t('components.instruments_bar.all_favourite_items'), slug: 'favourites'}), handle_current_instrument_name($t('components.instruments_bar.all_favourite_items'))"
        >{{ $t('components.instruments_bar.all_favourite_items') }} <i class="fas fa-star"></i></li>

        <li class="instrument"
            :class="{ active: is_active === instrument.id }"
            @click="scroll_to(`#${instrument.slug}`, instrument.id), handle_current_instrument_name(instrument.name)"
            v-for="instrument in filteredInstruments"
            :key="instrument.id"
        >
          <div class="v-html"
               v-html="instrument.name"
          ></div> <i class="fa-star favourite"
                     :class="{ fas: is_favourite(instrument.id), far: !is_favourite(instrument.id) }"
                     @click.stop="toggleFavouriteItem(instrument)"
                  ></i>

        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: { },
  data () {
    return {
      market: '',
      search: '',
      is_mobile_menu_closed: true,
      is_active: 'all',
      current_instrument_name: null
    }
  },
  mounted () {
    this.market = this.$route.matched[0].meta.market

    if (this.$route.params.instrument_slug === 'favourites') {
      this.is_active = 'favourites'
    }

    if (this.is_active === 'all') {
      this.current_instrument_name = this.$t('components.instruments_bar.all_items')
    } else {
      this.current_instrument_name = this.$t('components.instruments_bar.all_favourite_items')
    }

    this.$store.dispatch('market/instruments/loadFavourites')

    this.$cable.subscribe({
      channel: 'InstrumentChannel'
    })
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    handle_current_instrument_name (instrument) {
      this.current_instrument_name = instrument

      if (this.is_active !== 'favourites') {
        this.is_mobile_menu_closed = !this.is_mobile_menu_closed
      }
    },
    toggleInstrumentsMobileMenu () {
      this.is_mobile_menu_closed = !this.is_mobile_menu_closed
    },
    toggleFavouriteItem (instrument) {
      this.$store.dispatch('market/instruments/updateFavourites', { favourite_id: instrument.id })
    },
    select_intrument (instrument) {
      if (instrument.slug === 'all') {
        this.is_active = 'all'
        this.$router.push({ name: `${this.market}.instruments` })
      } else if (instrument.slug === 'favourites') {
        this.is_active = 'favourites'
        this.$router.push({ name: `${this.market}.instruments`, params: { instrument_slug: instrument.slug } })
      } else {
        this.is_active = instrument.id
      }
    },
    add_all_to_favourites () {
      var favourites = []

      this.instruments.map(function (instrument, key) {
        favourites.push(instrument.id)
      })

      this.$store.dispatch('market/instruments/addAllToFavourites', { favourites: favourites })
    },
    scroll_to (element, instrument_id) {
      if (!this.check_for_subscription()) {
        this.open_subscribe_popup()
      } else {
        this.is_active = instrument_id
        this.$scrollTo(element, 500, { container: '.content', offset: -60 })
      }
    },
    check_for_subscription () {
      if (this.current_user.open_door && this.current_user.open_door_accessed) {
        return true
      }

      const market_subscription = this.current_user.subscriptions.filter(
        s => s.markets.some(
          m => m.slug === this.market
        )
      ).length

      return !(this.current_user.subscriptions.length === 0 || market_subscription === 0)
    },
    open_subscribe_popup () {
      this.open_popup()
    }
  },
  channels: {
    InstrumentChannel: {
      received (data) {
        this.$store.dispatch('market/instruments/load_instrument_from_cable', { instrument: JSON.parse(data), market: this.market })
      }
    }
  },
  computed: {
    ...mapState({
      instruments: state => state.market.instruments.instruments,
      favourites: state => state.market.instruments.favourites,
      current_user: state => state.profile.account.current_user
    }),
    ...mapGetters({
      is_favourite: 'market/instruments/is_favourite'
    }),
    filteredInstruments () {
      return this.instruments.filter(instrument => {
        if (this.$route.params.instrument_slug === 'favourites') {
          return instrument.name.toLowerCase().includes(this.search.toLowerCase()) && this.is_favourite(instrument.id)
        }

        return instrument.name.toLowerCase().includes(this.search.toLowerCase())
      }).sort((a, b) => this.favourites.indexOf(b.id) - this.favourites.indexOf(a.id))
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-menu {
  height: 70px;
  border-bottom: 1px solid var(--ice-blue-charcoal-grey-three);
  padding: 22px 30px;
  position: relative;

  .dropdown-icon-wrapper {
    position: relative;
    top: 2px;
    margin-left: 14px;
    color: var(--cloudy-blue);
    position: absolute;
    top: 22px;
    right: 25px;
  }

  .v-html ::v-deep span {
    font-weight: 300;
  }
}

.instruments-bar {
  border-right: 1px solid var(--ice-blue-charcoal-grey-three);
  background-color: var(--white-black);

  @media (max-width: 575.98px) {
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 300;
    top: 0;
    overflow-y: scroll;
    height: 100%;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    @include basic-transition;

    height: 57px;
    line-height: 57px;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid var(--ice-blue-charcoal-grey-three);
    font-size: 14px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    color: var(--black-white);

    &:hover,
    &.active {
      background-color: var(--windows-blue);
      color: var(--white);
    }

    i {
      position: absolute;
      top: 21px;
      right: 15px;
      text-shadow: none;
    }
  }

  li.search {
    background-color: var(--white-black);
    transition: none;

    &:hover {
      background-color: transparent;
      color: var(--cloudy-blue);
    }

    input {
      width: 100%;
      border: none;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      color: var(--cloudy-blue);
      line-height: 56px;
      background-color: var(--white-black);

      &::placeholder {
        color: var(--cloudy-blue);
      }
    }

    .close {
      color: var(--cool-grey);
      font-size: 17px;
    }
  }

  li.instrument {
    .favourite {
      color: var(--windows-blue);

      &.far {
         color: var(--c-windows-blue-battleship-grey-three);
      }
    }

    &:hover,
    &.active {
      .favourite {
        color: var(--white);
      }
    }

    .v-html ::v-deep span {
      font-weight: 300;
    }
  }
}
</style>
