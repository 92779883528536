<template>
  <div class="updates-wrapper d-lg-block" :class="{'d-none': isMobileMenuOpen}">
    <div class="top-mobile-wrapper d-md-block d-lg-none">
      <span @click="close_intraday_updates_mobile_menu()"><i class="fas fa-times"></i></span>
    </div>

    <div class="updates">
      <ul>
        <li class="update"
            v-for="(report, index) in reports"
            v-bind:key="index"

        >
        <div :class="[
                'overlay-content-off',
                {'active': isHidden[index]}
               ]"
          >

            <b-row class="top-part">
              <b-col>
                <small>
                  <time-ago :refresh="30" :long="true" :datetime="report.sortable_date" />
                </small>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8" sm="9" lg="12" xl="9">
                <p v-html="report.content" v-if="check_for_subscription()"></p>
                <p v-if="!check_for_subscription()">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet.
                </p>
              </b-col>
              <b-col>
                <a :href="report.pdf_report" target="_blank" v-if="check_for_subscription()">
                  <img src="/img/pdf.svg" alt="PDF" width="50px">
                </a>

                <a href="#" @click="open_subscribe_popup()" v-if="!check_for_subscription()">
                  <img src="/img/pdf.svg" alt="PDF" width="50px">
                </a>
              </b-col>
            </b-row>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['isMobileMenuOpen'],
  data () {
    return {
      isFilterClosed: true,
      isInstradaySingleClose: true,
      intraday_update_chart_active_id: '',
      isHidden: [],
      constants: {
        max_intraday_number_of_graphs: 2,
        max_intraday_text_length: 450
      },
      search: '',
      filter_options: [
        {
          id: 1,
          name: 'Primary Markets'
        },
        {
          id: 2,
          name: 'Other Updates/Markets'
        },
        {
          id: 3,
          name: 'Trading notes'
        }
      ],
      market: null
    }
  },
  components: {
    // BaseCheckbox
  },
  mounted () {
    this.market = this.$route.matched[0].meta.market

    this.$store.dispatch('reports/loadReports')

    this.$cable.subscribe({
      channel: 'ReportChannel'
    })
  },
  computed: {
    ...mapState({
      reports: state => state.reports.reports,
      current_user: state => state.profile.account.current_user,
      popup_visibility: state => state.subscribe_popup.popup_visibility
    }),
    updates_bar () {
      const filtered_updates = this.updates.filter(
        update => update.markets?.some(
          market => market === this.market
        ) || update.instruments?.some(
          instrument => instrument.markets.includes(this.market)
        ) || update.markets?.some(
          market => market.slug === this.market
        )
      )

      filtered_updates.sort((a, b) => new Date(b.sortable_date) - new Date(a.sortable_date))

      if (this.search === '') {
        return filtered_updates
      }

      return filtered_updates.filter(
        update => update.linked_instruments?.some(
          linked_instrument => linked_instrument.name.toLowerCase().includes(this.search.toLowerCase())
        ) ||
          update.content?.toLowerCase().search(this.search.toLowerCase()) > -1
      )
    },
    toggle_show_charts_updates: {
      get () {
        return this.show_charts_updates
      },
      set (show_charts_updates) {
        this.$store.dispatch('updates/update_show_charts_updates', show_charts_updates)
      }
    }
  },
  methods: {
    ...mapActions({
      open_popup: 'subscribe_popup/open_popup'
    }),
    close_intraday_updates_mobile_menu () {
      this.$emit('close-updates-mobile-menu')
    },
    toggle_intraday_updates_filter () {
      this.isFilterClosed = !this.isFilterClosed
    },
    save_changes () {
      this.$notify({
        text: this.$t('components.intraday_updates.successfully_updated')
      })
    },
    intraday_update_list_toggle (index) {
      this.$set(this.isHidden, index, !this.isHidden[index])
    },
    comma_to_list (text, list_length, index) {
      if (index !== list_length) {
        return text + ', '
      }

      return text
    },
    check_for_subscription () {
      if (this.current_user.open_door && this.current_user.open_door_accessed) {
        return true
      }

      const market_subscription = this.current_user.subscriptions.filter(
        s => s.markets.some(
          m => m.slug === this.market
        )
      ).length

      return !(this.current_user.subscriptions.length === 0 || market_subscription === 0)
    },
    open_subscribe_popup () {
      this.open_popup()
    },
    show_intraday_single (intraday_update_id, chart_id) {
      this.intraday_update_chart_active_id = chart_id

      const modal_id = 'intraday_single_' + intraday_update_id
      this.$refs[modal_id][0].show(intraday_update_id)
    },
    scroll_to (element) {
      this.$scrollTo(element, 500, { container: '.content', offset: -60 })
    }
  },
  channels: {
    ReportChannel: {
      received (data) {
        this.$store.dispatch('reports/load_report_from_cable', { report: JSON.parse(data) })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.updates-wrapper {
  @media (max-width: 991.98px) {
    position: fixed;
    width: 100%;
    max-width: 100%;
    z-index: 300;
    overflow-y: scroll;
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--pale-grey-two-charcoal-grey-two);
  }

  .top-mobile-wrapper {
    background-color: var(--white-black);
    padding: 0 20px;
    text-align: right;

    span {
      @include basic-transition;
      line-height: 50px;
      color: var(--cool-grey);
      opacity: .5;
      font-size: 17px;
      cursor: pointer;

      &:hover {
        opacity: .25;
      }
    }
  }
}

.updates-header {
  padding: 0 25px 0 30px;

  h3 {
    font-size: 14px;
    color: var(--muted-blue-dark-sky-blue);
    font-family: 'Heebo', arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 57px;
    margin-bottom: 0;

    span {
      font-weight: 300;
      font-family: 'Montserrat', arial, sans-serif;
      text-transform: initial;
    }
  }

  h4 {
    cursor: pointer;
    font-size: 11px;
    color: var(--muted-blue);
    font-family: 'Montserrat', arial, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 57px;
    margin-bottom: 0;
    text-align: right;

    @media (min-width: 1200px) {
      img {
        margin-right: 8px;
        margin-top: -3px
      }
    }
  }

  .dropdown-icon-wrapper {
    position: relative;
    top: 1px;
  }

  @media (max-width: 991.98px) {
    padding: 0 20px 0 20px;

    .col {
      padding-left: 0;
    }

    h4 {
      text-transform: initial;
      color: var(--cool-grey);
      font-weight: 300;

      span {
        font-weight: 600;
        margin-right: 5px;
      }
    }
  }
}

.updates-filter {
  padding: 30px 25px 0 30px;
  position: absolute;
  width: 100%;
  z-index: 300;
  overflow-y: scroll;
  height: calc(100vh - 140px);
  top: 70px;
  background-color: var(--pale-grey-two);

  h3 {
    font-size: 16px;
    color: var(--battleship-grey);
    font-weight: 300;
  }

  span {
    @include basic-transition;
    color: var(--cool-grey);
    opacity: .5;
    font-size: 17px;
    cursor: pointer;

    &:hover {
      opacity: .25;
    }
  }

  h4 {
    font-size: 14px;
    color: var(--cool-grey);
    margin: 45px 0 25px 0;
    font-weight: 300;
  }

  .btn {
    background-color: var(--muted-blue);
    margin-top: 35px;
    height: 50px;
    min-width: 152px;
  }
}

.updates {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li.search {
    input {
      width: 100%;
      border: none;
      padding: 0 30px;
      margin: 0;
      font-size: 14px;
      font-weight: 300;
      color: var(--cloudy-blue);
      line-height: 56px;
      background-color: var(--pale-grey-two-charcoal-grey-two);

      &::placeholder {
        color: var(--cloudy-blue);
      }

      @media (max-width: 991.98px) {
        padding: 0 20px;
      }
    }
  }

  .overlay-content {
    height: auto;
    max-height: 190px;
    overflow: hidden;
    @include ease-transition(1s);

    &.active {
      max-height: 100vh;
      margin-bottom: 20px;

      .overlay-buttons {
        background-image: none;
      }
    }

    .overlay-buttons {
      position: absolute;
      bottom: 0;
      padding: 38px 0 18px;
      width: 100%;
      left: 0;
      font-size: 11px;
      background-image: linear-gradient(to bottom, #f5f8fa00, var(--pale-grey-two-charcoal-grey-two) 59%);

      a {
        cursor: pointer;
        color: var(--muted-blue);
      }
    }
  }

  li.update {
    padding: 20px 25px 25px 30px;
    border-bottom: 1px solid var(--ice-blue-three-charcoal-grey-three);
    position: relative;

    &:empty {
      border-bottom: 0;
    }

    &.instrument {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;

      &.instrument-visible {
        visibility: visible;
        opacity: 1;
      }
    }

    &.trading-notes-and-instruments {
      padding-top: 0;
      padding-bottom: 0;

      p {
        padding: 13px 0;
      }

      a {
        display: inline-block;
        font-family: 'Montserrat', arial, sans-serif;
        font-weight: 400;
        cursor: pointer;

        &:hover {
          color: var(--muted-blue);
        }
      }

      span:not(:first-child):not(:empty)::before {
        content: ', ';
      }

      span:empty + span:not(:empty)::before {
        content: '';
      }

      span:not(:empty) ~ span:empty + span:not(:empty)::before {
        content: ', ';
      }
    }

    .top-part {
      margin-bottom: 10px;
    }

    h5 {
      font-size: 15px;
      color: var(--charcoal-grey-white);
      display: inline-block;
      margin-right: 9px;
    }

    .market-icon {
      margin-top: -3px;
      margin-right: 4px;
    }

    p {
      font-size: 14px;
      font-family: 'Athelas', arial, sans-serif;
      letter-spacing: 0.08px;
      color: var(--charcoal-grey-white);
      line-height: 1.46;
      margin: 0;
    }

    .col {
      text-align: right;

      small,
      ::v-deep .v-time-ago__text {
        font-size: 12px;
      }

      .intraday-image {
        margin-top: 15px;
        position: relative;
        cursor: pointer;

        &:first-child {
          margin-top: 0;
        }

        img {
          height: auto;
          width: 80px;
        }

        .image-overlay {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 100%;
          background-color: var(--white-70);
          border: 1px solid var(--blue-grey-30);
          text-align: left;
          padding-left: 8px;

          h6 {
            font-size: 11px;
            margin-bottom: 0;
            width: 75%;
            margin-top: 5px;
            color: var(--flat-blue-two);
            text-transform: uppercase;
          }

          small,
          ::v-deep .v-time-ago__text {
            font-size: 11px;
            color: var(--flat-blue-two);
            position: absolute;
            bottom: 1px;
          }

          .graph-zoom-icon {
            width: 18px;
            min-height: 18px;
            max-height: 18px;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          &:hover {
            .graph-zoom-icon {
              opacity: .8;
            }
          }
        }

        @media (min-width: 1200px) and (max-width: 1400px) {
          .col {
            padding-left: 0;
          }
          img {
            width: 75px;
          }

          .image-overlay {
            width: 75px;
          }
        }
      }
    }

    @media (max-width: 991.98px) {
      padding: 20px 20px 25px;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      .col {
        padding-left: 0;
      }
    }

    @media (max-width: 1199.98px) and (min-width: 992px) {
      .col .intraday-image {
        display: inline-block;
        margin-right: 10px;

        &:first-child {
          margin-top: 15px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (max-width: 360px) {
      .col {
        padding-left: 0;
      }
    }
  }
}
</style>
